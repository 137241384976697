* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #020617;
  color: #e2e8f0;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}

.app-box {
  background-color: #0f172a;
  padding: 20px;
  margin: 30px;
  border-radius: 20px;
  box-shadow: 0px 4px 15px #00000033;
  /*width: 500px;*/
  position: relative; /* Ensures the menu stays inside the app box */
}

/* Header */
.header {
  display: flex;
  flex-direction: column; /* Stack the logo and title vertically */
  align-items: center; /* Center the logo and title */
  position: relative;
  padding-top: 60px; /* Adds space above the logo to avoid overlap with the menu */
}

.header img {
  height: 68px;
  margin-bottom: 20px; /* Adds space between logo and title */
}

h1 {
  font-size: 42px;
  text-transform: uppercase;
  font-family: "Quicksand", sans-serif;
}

/* Menu */
.menu {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 1000;
}

.menu button {
  font-size: 50px;
  background: none;
  border: none;
  cursor: pointer;
  color: #e2e8f0;
  padding: 0;
  width: 50px;
  height: 50px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #334155;
  padding: 10px 0;
  z-index: 10;
  min-width: 150px;
  display: flex;
  flex-direction: column;
}

.dropdown-menu button {
  color: #e2e8f0;
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-menu button:hover {
  background-color: #020617;
}

/* Suggestion Box */
.suggestion {
  background-color: #1e293b; /* Different background color */
  padding: 20px; /* Adds space inside the box */
  margin: 30px 0; /* Adds space outside the box */
  border-radius: 8px; /* Rounds the corners */
  color: #e2e8f0; /* Text color */
  border: 1px solid #e2e8f0; /* Lighter border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  line-height: 1.4;
  height: 100px;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
}

#next-btn {
  font-size: 70px;
  background: none;
  border: none;
  cursor: pointer;
  color: #e2e8f0;
}

.buttons-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttons-container button {
  font-size: 25px;
  background: none;
  cursor: pointer;
  color: #e2e8f0;
  margin: 8px;
  padding: 5px 8px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  display: flex; 
  align-items: center; 
  justify-content: center;
}

.about-page .buttons button,
.favorites-page .buttons button,
.closed-page .buttons button,
.snoozed-page .buttons button {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: #e2e8f0;
  margin: 8px;
  padding: 5px 8px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 20px;
}

.about-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 20px 0;
}

.favorites-page,
.closed-page,
.snoozed-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 20px 0;
}

.about-page h2,
.favorites-page h2,
.closed-page h2,
.snoozed-page h2 {
  font-size: 28px;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.how-to-content {
  background-color: #1e293b;
  border-radius: 8px;
  padding: 20px;
  margin: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  align-self: flex-start;
  width: 100%;
}

.favorites-content,
.closed-content,
.snoozed-content {
  background-color: #1e293b;
  border-radius: 8px;
  padding: 20px;
  margin: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.how-to-content ul,
.favorites-page ul,
.closed-page ul,
.snoozed-page ul {
  list-style-type: none;
  padding: 0;
}

.how-to-content li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;
  text-align: left;
}

.favorites-page li,
.closed-page li,
.snoozed-page li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;
  text-align: left;
}

.how-to-content li ion-icon {
  font-size: 24px;
  margin-right: 10px;
}

.favorites-page li ion-icon,
.closed-page li ion-icon,
.snoozed-page li ion-icon {
  font-size: 24px;
  flex-shrink: 0;
  cursor: pointer;
  margin-left: auto;
}
